// インテリジェント
// 案件詳細

const state = {
  sScId: '',
  sLblComName: '企業名',
  sLblScYearMonth: '実施年月',
  sLblScAnswerStartDate: 'アンケート回答開始年月日',
  sLblScAnswerEndDate: 'アンケート回答終了年月日',
  sLblScConfAnswer: '回答形式',
  sLblScConfNotice: '結果通知形式',
  sLblAnswerStatus: '回答状態',
  sLblAnswerCompDate: '回答完了日',

};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
