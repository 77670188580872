import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit.css";
import "uikit/dist/css/uikit.min.css";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
UIkit.use(Icons);

const vueObj = createApp(App).use(Quasar, quasarUserOptions);
vueObj.config.globalProperties.$axios = axios.create({
  baseURL: "https://stress-check-web.dev-holonicsystem.com",
  // baseURL: 'http://localhost:8000/',
});
vueObj.use(store);
vueObj.use(router);
vueObj.config.errorHandler = function (err, vm, info) {
  console.log("catched by `Vue.config.errorHandler`", err.toString());
  console.log(err, vm, info);
};

vueObj.mount("#app");
