// 回答者
// ホーム

const state = {
  sLblScStatus: '実施状態',
  sLblScYearMonth: '実施年月',
  sLblScAnswerStartDate: 'アンケート回答開始年月日',
  sLblScAnswerEndDate: 'アンケート回答終了年月日',
  sLblScConfAnswer: '回答形式',
  sLblScConfNotice: '結果通知形式',
  sLblAnswerStatus: '回答状態',
  sLblAnswerCompDate: '回答完了日',
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  // actions
}
